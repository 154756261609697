import React from "react"
import styled from "styled-components"
import { useState, useContext } from "react"
// components
import CheckoutContainer from "../../components/checkoutB/CheckoutContainer"
import ProductCard from "../../components/checkoutB/StepOne/ProductCard/ProductCard"
import { AlignContent } from "../../components/shared/container.style"
import FirstStepList from "../../components/checkoutB/StepOne/FirstStepList"
import Perk from "../../components/checkoutB/StepOne/Perk"
import Faq from "../../components/faq"
import Trusted from "../../components/trusted"
import Loader from "../../components/shared/loader"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
// helper functions
import { FastSpringPrice } from "../../utils/FastSpringPrices"

// utils
import {
  regularProductCard,
  regularText,
} from "../../components/checkoutB/StepOne/ProductCard/ProductCardStyles"

// JSON
import MainCheckout from "../../../checkoutContent/lp5Checkout.json"

// context API provider
import CheckoutNewContextProvider from "../../Contexts/CheckoutNewContext"
import { CheckoutNewContext } from "../../Contexts/CheckoutNewContext"
import Seo from "../../components/seo"

const CheckoutHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1040px;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: max-content;
`

const CheckoutAlignContent = styled(AlignContent)`
  gap: 25;
  height: max-content;
  padding: 16px;
  justify-content: center;
  align-items: center;
  min-height: 541px;
  @media (max-width: 1140px) {
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
`

const PerkAlignContent = styled(AlignContent)`
  gap: 25;
  height: max-content;
  padding: 16px;
  margin-bottom: 64px;
  @media (max-width: 1140px) {
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
`

const Line = styled.div`
  background: rgb(245, 245, 245);
  width: 97%;
  height: 2px;
  margin-bottom: 48px;
  margin-top: 24px;
`

const Navbar = styled.div`
  height: 75px;

  @media (max-width: 640px) {
    height: 123px;
  }
`

function CheckoutNew() {
  // function that turns array to the objects so you can more easily and faster access certain values - you can have this on backend to get back production ready object
  const obj = {}
  FastSpringPrice.products.forEach(elem => {
    obj[elem.product] = elem
  })

  const [active, setActive] = useState(false)

  // const tag =
  //   FastSpringPrice.products[0].pricing[getCountry().countryTag.toString()]
  //     .quantityDiscount["1"]?.unitPriceDisplay

  // const newTag =
  //   FastSpringPriceFiltered["pdf-two-year"].pricing["HR"].quantityDiscount["1"]
  //     .unitPriceDisplay

  //   const tag = FastSpringPrice.products[5].pricing[getCountry().countryTag.toString()] - neki nemaju quantityDiscount tak da moras sloziti uvijet da ak nema toga da ispise display

  // const tag =
  //   FastSpringPrice.products[5].pricing[getCountry().countryTag.toString()]
  //     .quantityDiscount["1"]?.unitPriceDisplay ||
  //   FastSpringPrice.products[5].pricing[getCountry().countryTag.toString()]
  //     .display

  // -uvjet

  //   const tag = FastSpringPrice.products.length - 32 ih ima
  const { selectedOption } = useContext(GlobalAuthContext)

  return (
    <CheckoutNewContextProvider>
      <Seo title="Checkout: PDF Pro" noindex={true} />
      <Navbar />
      <CheckoutContainer setActive={setActive}>
        <CheckoutHolder>
          {/* {Intl.DateTimeFormat().resolvedOptions().timeZone}
          <div>{getCountry().countryName}</div>
          <div>{getCountry().countryTag}</div> */}
          {/* separator */}

          <FirstStepList />
          <CheckoutAlignContent>
            {typeof window === "undefined" ? (
              <Loader />
            ) : (
              <>
                <ProductCard productInfo={MainCheckout.oneYearSubscription} />

                <ProductCard
                  styles={regularProductCard}
                  textStyle={regularText}
                  productInfo={MainCheckout.monthlySubscription}
                />
                {/* <ProductCard
                  productInfo={MainCheckout.twoYearsSubscription}
                  styles={regularProductCard}
                  textStyle={regularText}
                /> */}
              </>
            )}
          </CheckoutAlignContent>
          <Line />
          <PerkAlignContent>
            <Perk
              image={1}
              title={"Dedicated Support"}
              desc={"Get help from our knowledgeable customer support agents"}
            />
            <Perk
              image={2}
              title={"100% Secure Transaction"}
              desc={
                "Your information is protected through SSL encryption technology"
              }
            />
            <Perk
              image={3}
              title={"30-Day Guarantee"}
              desc={"Cancel within 30 days and we will refund you hassle-free"}
            />
          </PerkAlignContent>
        </CheckoutHolder>
      </CheckoutContainer>
      {!active && (
        <>
          <Faq />
          <Trusted />
        </>
      )}
    </CheckoutNewContextProvider>
  )
}

export default CheckoutNew
